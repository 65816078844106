<template>
  <div>
    <v-row class="ma-0">
      <v-col class="d-flex align-center">
        <v-btn
          elevation="0"
          size="large"
          icon
          color="primary"
          class="mr-2"
          name="back-button"
          @click="$router.back()"
        >
          <v-icon large color="primary">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <span
          class="text-h6 font-weight-bold text--primary pa-0 text-uppercase"
        >
          Shipments</span
        >
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <v-btn small depressed color="primary" @click="fetchShipmentStatus()">
          <v-icon small class="mr-1">mdi-reload</v-icon>
          Fetch Status
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col
        cols="12"
        lg="3"
        class="overflow-y-auto py-0 pr-lg-0"
        style="max-height: calc(85vh)"
      >
        <v-card elevation="1" rounded="lg" class="shadow-on-hover">
          <v-card rounded="lg" class="primary">
            <v-card-text class="pa-2">
              <v-row class="ma-0">
                <v-col cols="9" lg="9" class="pa-1">
                  <span class="text-caption text-white">
                    Shipper Reference Number
                  </span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{
                      shipmentObject.shipper_reference_number
                        ? shipmentObject.shipper_reference_number
                        : "-"
                    }}
                  </span>
                  <span>
                    <v-tooltip
                      bottom
                      v-if="shipmentObject.shipper_reference_number"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="
                            copyReferenceNumber(shipmentObject.reference_number)
                          "
                        >
                          <v-icon color="white" small>
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      Copy Reference Number
                    </v-tooltip>
                  </span>
                </v-col>

                <v-col
                  cols="3"
                  lg="3"
                  class="pa-1 d-flex align-center justify-end"
                >
                  <div class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="small"
                          class="text--primary"
                        >
                          <span class="text-right">
                            {{ shipmentObject.awb_number }}
                          </span>
                        </v-chip>
                      </template>
                      AWB Number
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col cols="12 pa-1">
                  <v-divider class="white"></v-divider>
                </v-col>

                <v-col cols="12" class="pa-1">
                  <span class="text-caption text-white">Created</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{
                      shipmentObject && shipmentObject.created
                        ? convertEventTimeStamp(shipmentObject.created) +
                          " (" +
                          formatLastUpdatedTime(shipmentObject.created) +
                          ")"
                        : null
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" class="py-0">
        <div class="white">
          <v-row class="ma-0">
            <v-col cols="12" lg="9" class="border-right-grey">
              <v-row>
                <v-col
                  cols="4"
                  v-for="(detail, key) in basicInformation"
                  :key="key"
                >
                  <span class="text-caption text-grey">{{ detail.label }}</span>
                  <v-spacer />
                  <span class="text-body-2 text--primary font-weight-bold">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="3">
              <v-row>
                <v-col
                  cols="12"
                  v-if="
                    shipmentObject &&
                    shipmentObject.response &&
                    shipmentObject.response.error
                  "
                >
                  <span class="text-caption text-red"> Failed Reason </span>
                  <v-spacer />
                  <span class="text-body-2 font-weight-bold">
                    <!-- {{ shipmentObject.response.error }} -->
                    <!-- <v-list
                        dense
                        class="ml-2 pa-0"
                        style="background: inherit !important"
                        v-for="(error, key, i) in shipmentObject.response.error"
                        :key="i"
                      >
                        <v-list-item dense>
                          <span class="red--text font-weight-bold"
                            >{{ key }} -{{ error }}</span
                          >
                        </v-list-item>
                      </v-list> -->
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" lg="9" class="pt-3 pr-lg-0">
        <v-card elevation="0" rounded="lg">
          <v-card-text class="ma-0 pa-0">
            <v-row no-gutters>
              <v-col cols="12" class="ma-0 pa-0">
                <v-tabs
                  v-model="currentTab"
                  centered
                  grow
                  slider-color="secondary"
                  slider-size="3"
                >
                  <v-tab name="webhook_logs">Webhook Logs</v-tab>
                  <v-tab name="system_logs">System Logs</v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-window
              v-model="currentTab"
              style="background-color: transparent !important"
            >
              <!-- Webhook Logs -->
              <v-window-item>
                <v-card class="rounded-lg">
                  <v-card-text class="pa-2">
                    <v-data-table
                      :headers="clientWebhookLogColumns"
                      :items="clientWebhookLogsData"
                      fixed-header
                      class="overflow-y-auto"
                      style="height: 500px"
                    >
                      <template #item.created="{ item }">
                        {{ formatLastUpdatedTime(item.created) }}
                      </template>
                      <template #item.is_success="{ item }">
                        <span
                          class="text-white text-body-2 mr-1 font-weight-bold"
                        >
                          <v-icon color="red" v-if="!item.is_success">
                            mdi-close-circle
                          </v-icon>
                          <v-icon v-else color="green"
                            >mdi-checkbox-marked-circle</v-icon
                          >
                        </span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-window-item>
              <!-- System Logs -->
              <v-window-item>
                <v-data-table
                  :headers="systemColumns"
                  :items="systemLogs"
                  fixed-header
                  class="overflow-y-auto"
                  style="height: 500px"
                >
                  <template #item.created="{ item }">
                    {{ formatLastUpdatedTime(item.created) }}
                  </template>
                  <template #item.is_success="{ item }">
                    <span class="text-white text-body-2 mr-1 font-weight-bold">
                      <v-icon color="red" v-if="!item.is_success">
                        mdi-close-circle
                      </v-icon>
                      <v-icon v-else color="green">
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </span>
                  </template>
                  <template #item.actions="{ item }">
                    <v-btn text @click="moreDetails(item.payload)">
                      <v-icon color="primary">mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-tabs
          v-model="logsTab"
          class="ma-0 pa-0"
          centered
          grow
          slider-color="secondary"
          slider-size="3"
        >
          <v-tab name="awb_logs">AWB Logs</v-tab>
          <v-tab name="history_logs">History Logs</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-window
          v-model="logsTab"
          style="background-color: transparent !important"
        >
          <v-window-item>
            <v-card
              elevation="0"
              class="overflow-y-auto rounded-lg"
              style="height: 500px"
            >
              <v-card-text
                v-if="
                  shipmentObject &&
                  shipmentObject.awb_logs &&
                  !shipmentObject.awb_logs.length
                "
              >
                <div class="d-flex justify-center">No AWB Logs To Show</div>
              </v-card-text>
              <v-card-text v-else class="ma-0 pa-0">
                <v-timeline dense small>
                  <v-timeline-item
                    v-for="item in shipmentObject.awb_logs"
                    :key="item.id"
                    small
                  >
                    <v-row no-gutters>
                      <v-col cols="12" class="primary--text">
                        {{ item.message }}
                      </v-col>
                      <v-col cols="6">
                        {{
                          item.created
                            ? convertEventTimeStamp(item.created)
                            : null
                        }}
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-window-item>
          <v-window-item>
            <v-card
              elevation="0"
              class="overflow-y-auto rounded-lg"
              style="height: 500px"
            >
              <v-card-text v-if="!historyLogs.length">
                <div class="d-flex justify-center">No History Logs To Show</div>
              </v-card-text>
              <v-card-text v-else class="ma-0 pa-0">
                <v-timeline dense small>
                  <v-timeline-item
                    v-for="item in historyLogs"
                    :key="item.id"
                    small
                  >
                    <v-row no-gutters>
                      <v-col cols="12" class="primary--text">
                        {{ item.message }}
                      </v-col>
                      <v-col cols="12">
                        {{
                          item.created
                            ? convertEventTimeStamp(item.created)
                            : null
                        }}
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
      <!-- <v-row no-gutters>
        <v-col cols="6">
          <span style="font-size: 10px" class="text-caption">
            {{ log.status }} (
            {{ log.created ? convertEventTimeStamp(log.created) : null }})
          </span>
        </v-col>
        <v-col cols="12">
          <h5 class="black--text text-caption">
            {{ log.message }}
          </h5>
        </v-col>
        <v-col cols="12">
          <span style="font-size: 10px" class="text-caption">
            Customer Name - {{ log.customer_name }}, Current Branch -
            {{ log.current_branch }}
          </span>
        </v-col>
      </v-row> -->
    </v-row>

    <BaseDialog
      v-model="showPayloadPreview"
      width="60vh"
      title="Payload"
      @closeDialog="showPayloadPreview = false"
    >
      <template #dialogContent>
        <div id="pdf-content">
          <pre>{{ payloadPreviewData }}</pre>
        </div>
      </template>
      <template #actions>
        <v-btn small text>
          <v-icon color="primary" @click="copyPayload">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { bus } from "@/main";
import { formatLastUpdated, convertDateTime } from "@/utils/functions";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import EditOrderForm from "./EditOrderForm.vue";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,
    BaseDialog,
    EditOrderForm,
  },
  data() {
    return {
      currentTab: "webhook_logs",
      logsTab: "awb_logs",
      shipmentObject: {},
      showPayloadPreview: false,
      payloadPreviewData: {},

      systemLogs: [],
      historyLogs: [],
      clientWebhookLogsData: [],

      systemColumns: [
        { text: "Message", value: "message" },
        { text: "Added By", value: "added_by", maxWidth: 200 },
        {
          text: "Is Success",
          value: "is_success",
          maxWidth: 150,
        },
        { text: "Status Code", value: "status_code", maxWidth: 150 },
        {
          text: "Created",
          value: "created",
          minWidth: 180,
        },
        {
          text: "More Details",
          value: "actions",
          maxWidth: 150,
        },
      ],
      clientWebhookLogColumns: [
        { text: "AWB Number", value: "awb_number" },
        { text: "Organization", value: "organization_name" },
        { text: "Client", value: "client_name" },
        { text: "Method", value: "method" },
        { text: "Status", value: "status" },
        { text: "Response Code", value: "status_code" },
        {
          text: "Success",
          value: "is_success",
        },
        {
          text: "Created",
          value: "created",
        },
      ],
    };
  },
  watch: {
    currentTab(val) {
      if (val == 0) {
        this.getClientWebhookLogsList();
      } else if (val == 1) {
        this.getClientShipmentSystemLogs();
      }
    },
    logsTab(val) {
      if (val == 0) {
      } else if (val == 1) {
        this.getClientShipmentHistoryLogs();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicInformation() {
      return [
        {
          label: "Integration Partner",
          value: this.shipmentObject.integration_partner_name,
        },
        {
          label: "Organization",
          value: this.shipmentObject.organization_name,
        },
        {
          label: "Client",
          value: this.shipmentObject.client_name,
        },
        {
          label: "Last AWB Status",
          value: this.shipmentObject.last_status_received,
        },
        {
          label: "Last Status Updated At",
          value: this.formatLastUpdatedTime(
            this.shipmentObject.last_status_received_at
          ),
        },
      ];
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      if (value != null || value != undefined) {
        return convertDateTime(value);
      }
    },
    formatLastUpdatedTime(value) {
      if (value != null || value != undefined) {
        return formatLastUpdated(value);
      }
    },
    async viewLogDetails() {
      let id = this.$route.params.id;
      await this.$api.clientAWB
        .viewLogDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.shipmentObject = res.data;
          this.getClientWebhookLogsList();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error getting data",
            color: "red",
          });
        });
    },
    getClientWebhookLogsList(params = {}) {
      bus.$emit("showLoader", true);
      params.awb = this.$route.params.id;

      this.$api.outboundLogs
        .getClientWebhookLogsList({ ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.clientWebhookLogsData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    getClientShipmentSystemLogs(params = {}) {
      params.awb = this.$route.params.id;

      this.$api.inbound
        .getClientShipmentSystemLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.systemLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting system logs",
            color: "red",
          });
        });
    },
    getClientShipmentHistoryLogs(params = {}) {
      params.awb = this.$route.params.id;

      this.$api.inbound
        .getClientShipmentHistoryLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.historyLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting history logs",
            color: "red",
          });
        });
    },
    fetchShipmentStatus() {
      bus.$emit("showLoader", true);

      this.$api.inbound
        .fetchShipmentStatus(this.shipmentObject.id)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Status Fetched Successfully",
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.data?.error?.message,
            color: "red",
          });
        });
    },
    moreDetails(payload) {
      this.payloadPreviewData = payload;
      this.showPayloadPreview = true;
    },
    copyPayload() {
      if (this.payloadPreviewData) {
        navigator.clipboard.writeText(JSON.stringify(this.payloadPreviewData));
        bus.$emit("showToastMessage", {
          message: "Payload Copied Successfully..",
          color: "green",
        });
      }
    },
    copyReferenceNumber(refNumber) {
      navigator.clipboard.writeText(refNumber);
      bus.$emit("showToastMessage", {
        message: "Reference Number Copied",
        color: "success",
      });
    },
  },
  mounted() {
    this.viewLogDetails();
  },
};
</script>
<style scoped>
.light_background {
  background-color: #17b7d0;
}

.v-tab--active {
  background-color: #112f4f !important;
  color: white;
}

.primary-detail-card {
  background: linear-gradient(
    50deg,
    primary 55%,
    gradiant-shade,
    primary 75%
  ) !important;
}
</style>
