<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="vendorShipmentAwbList"
        ref="vendorShipmentAwbList"
        title="Vendor AWBs"
        :hasTabs="false"
        searchable
        :table-header="vendorShipmentAwbColumns"
        :table-data="vendorShipmentAwbList"
        :rowHeight="40"
        :context="context"
        :total="totalItems"
        localStorageKey="vendorAwbListColumns"
        @getList="getVendorShipmentAwbList"
        @selectionChanged="selectionChanged"
      >
        <template #leftFilterSlot>
          <div>
            <FilterPanel
              filter_for="vendor_awb_list"
              @applyFilters="applyFilter"
            />
          </div>
        </template>
        <template #rightSlot>
          <DateRangePicker
            style="max-width: 300px"
            id="vendor-awb-list-date-range"
            v-model="dateRange"
            name="vendor-awb-list-date-range"
            @update:modelValue="updateDateRange"
          />
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!selectedShipments.length"
                class="ma-1"
                color="primary"
                small
                depressed
                @click="getVendorFormattedStatusList()"
              >
                Update Status
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in formattedStatusList"
                :key="index"
                @click="
                  getSelectedFormattedStatus(item.formatted_vendor_status)
                "
              >
                <v-list-item-title>{{
                  item.formatted_vendor_status
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- <v-btn
            :disabled="selectedItems.length == 0"
            small
            color="primary"
            class="ma-1"
            @click="bulkResend()"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn> -->
        </template>

        <template #dialogs>
          <BaseDialog
            title="Status Update"
            v-model="showConfirmationDialog"
            max-width="400"
          >
            <template #dialogContent>
              <span class="text-lg-subtitle-1 font-weight-bold">
                Are you sure you want to update status ?
              </span>
            </template>
            <template #actions>
              <v-btn
                color="error"
                small
                @click="showConfirmationDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn color="success" small @click="updateStatus">
                Confirm
              </v-btn>
            </template>
          </BaseDialog>
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import FilterPanel from "@/components/common/FilterPanel.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import AWBListButtons from "@/components/AgGridButtons/AWBListButtons.vue";
import DateRangePicker from "@/components/BaseComponents/DateRangePicker.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import { bus } from "@/main";

export default {
  name: "VendorAWBList",
  components: {
    BaseListLayout,
    BaseSelect,
    FilterPanel,
    ShipmentCellRenderer,
    AWBListButtons,
    DateRangePicker,
    BaseDialog,
  },
  data() {
    return {
      dateRange: {
        start_date: new Date(),
        end_date: new Date(),
      },
      totalItems: 0,
      filters: {},
      vendorShipmentAwbList: [],
      vendorShipmentAwbColumns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          maxWidth: 50,
          pinned: "left",
        },
        {
          headerName: "Vendor AWB",
          field: "vendor_awb",
          autoHeight: true,
          pinned: "left",
          minWidth: 200,
          cellClass: "text--primary font-weight-bold",
        },
        {
          headerName: "Organization AWB",
          field: "order_awb",
          minWidth: 170,
          autoHeight: true,
        },
        { headerName: "Organization", field: "organization", minWidth: 150 },
        {
          headerName: "Vendor",
          field: "vendor",
          minWidth: 100,
        },
        {
          headerName: "Organization AWB Status",
          field: "organization_awb_status",
          minWidth: 190,
        },
        {
          headerName: "Vendor Status",
          field: "last_vendor_status_sent_to_org",
          minWidth: 190,
        },
        {
          headerName: "Last Status Sent To Organization",
          field: "last_status_sent_to_org",
          minWidth: 190,
        },
        {
          headerName: "Last Update Received At",
          field: "last_update_received_at",
          minWidth: 190,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Last Update Sent At",
          field: "last_update_sent_to_org_at",
          minWidth: 190,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          minWidth: 180,
          pinned: "right",
          cellRenderer: "AWBListButtons",
        },
      ],

      awbLogs: [],
      showLogsDialog: false,

      rowData: {},
      showClientWebhookLogsDialog: false,

      selectedShipments: [],
      formattedStatusList: [],
      selectedFormattedStatus: "",
      showConfirmationDialog: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    updateDateRange(dates) {
      this.dateRange.start_date = dates.start_date;
      this.dateRange.end_date = dates.end_date;

      this.filters.created_after = this.dateRange.start_date;
      this.filters.created_before = this.dateRange.end_date;

      this.applyFilter(this.filters);
    },
    getVendorShipmentAwbList(params = {}) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("vendor_awb_list_filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }

      this.$api.vendorShipments
        .getVendorShipmentAWBList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.vendorShipmentAwbList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting request log list",
            color: "red",
          });
        });
    },
    applyFilter(filters) {
      localStorage.setItem("vendor_awb_list_filters", JSON.stringify(filters));
      this.refreshList();
    },
    refreshList() {
      this.$refs.vendorShipmentAwbList.refreshList();
    },
    viewDetails(id) {
      let rowId = id;
      let awb = id;
      this.$router.push({
        name: "vendor-shipment-details",
        query: { id: rowId, awb: awb },
      });
    },

    // Update Status Methods
    selectionChanged(selectedRows) {
      this.selectedShipments = selectedRows;
    },
    getVendorFormattedStatusList() {
      let selectedVendor = this.selectedShipments[0].organization_vendor_id;

      this.$api.organizationVendor
        .getOrganizationVendorStatusDetails(selectedVendor)
        .then((res) => {
          this.formattedStatusList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSelectedFormattedStatus(status) {
      this.selectedFormattedStatus = status;
      this.showConfirmationDialog = true;
    },
    updateStatus() {
      let selectedVendorAwbs = [];

      this.selectedShipments.map((item) => {
        selectedVendorAwbs.push(item.vendor_awb);
      });

      let params = {
        vendor_awbs: selectedVendorAwbs,
        status: this.selectedFormattedStatus,
      };
      this.$api.vendorShipments
        .updateVendorStatus(params)
        .then((res) => {
          this.refreshList();
          this.showConfirmationDialog = false;
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.message,
            color: "red",
          });
        });
    },
  },
  created() {
    localStorage.removeItem("vendor_awb_list_filters");
  },
};
</script>

<style></style>
